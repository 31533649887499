/* width */
body::-webkit-scrollbar {
    width: 15px;
}

/* Track */
body::-webkit-scrollbar-track {
    background: #73ADD6;
}

/* Handle */
body::-webkit-scrollbar-thumb {
    background: #D371AA;
    border-radius: 1em;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
    background: #A12C71;
}

.main-frame {
    width: min(1000px, 100%);
    margin: auto;
    __box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    background-color: white;

    border-width: 6em;
    border-style: solid;
    background-clip: padding-box;
    border-image: url("img/grass-transparent.png") 30% round;
}

.order-page {
}

.order-page .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.product-display {
    margin: 1em;
    width: clamp(200px, 30%, 400px);
}

.logo {
    width: 8em;
    height: 8em;
    background-image: url("img/oeuf_petit_left.png");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    transition: all 0.5s;
}

.logo:hover {
    background-image: url("img/oeuf_gros_left.png");
    transition: all 0.5s;
}

.small-logo-parent {
    display: flex;
    align-items: center;
}

.small-logo {
    width: 50px;
    height: 50px;
    background-image: url("img/small_oeuf_petit_left.png");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: contain; /* Resize the background image to cover the entire container */
    transition: all 0.5s;
}

.small-logo-parent:hover .small-logo {
    background-image: url("img/small_oeuf_gros_left.png");
    transition: all 0.5s;
}

.text-logo {
    width: min(80%, 800px);
    height: max(5vh, 50px);
    background-image: url("img/text.png");
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: contain; /* Resize the background image to cover the entire container */
}

.header {
    background-image: url("img/vichy_small.png");
    background-repeat: repeat;
    width: min(1000px, 100%);
    margin: auto;
}

.header button {
    margin-right: 1em;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

.full-cover {
    height: fit-content;
    width: 100%;
    z-index: -5;
    position: absolute;
    background-image: url("img/landing/fond_bleu_small_square.jpg");
    background-position: initial; /* Center the image */
    background-repeat: repeat; /* Do not repeat the image */
    background-size: 10em; /* Resize the background image to cover the entire container */
}

.full-cover-content {
}

.full-covered-content {
    z-index: 1;
}

.parent-parent-cover {
    background-color: white;
    width: min(1000px, 80%);
    margin: auto;
    border-width: 3em;
    border-style: solid;
    background-clip: padding-box;
    border-image: url("img/grass-transparent.png") 30% round;

}


.parent-cover {
    height: auto;
}


.image-cover {
}


.inner-cover {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    filter: none;
}

.descBlock {
    display: flex;
    padding: 2em;
}

.descBlock:nth-child(odd) {
    flex-direction: row-reverse;
}

.descBlock p {
    flex: 0 1 auto;
    font-size: 1.8em;
    padding: 2em;
}

.imgDesc {
    flex: 1 0 auto;
    width: 66vh;
    height: auto;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    transition: all 0.5s;
}


#descImg1 {
    background-image: url("img/landing/texture_zoom.jpg");
}

#descImg2 {
    background-image: url("img/landing/dual.jpg");
}

#descImg3 {
    background-image: url("img/landing/profil.jpg");
}

#descImg4 {
    background-image: url("img/landing/all.jpg");
}

.product-display-actions-container{
    position: absolute;
}

.product-display-actions {
    position: absolute;
    top: -8em;
}

.product-display-actions > * {
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
}

@media (max-aspect-ratio: 2/1) {
    body {
        font-size: x-small;
    }

    .main-frame {
        width: min(1000px, 80%);
        margin: auto;
        __box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
        background-color: white;

        border-width: 3em;
        border-style: solid;
        background-clip: padding-box;
        border-image: url("img/grass-transparent.png") 30% round;
    }

    .header > * {
        display: flex;
        flex-wrap: wrap;
    }

    .header button[class*='MuiButtonBase-'] {
        font-size: 1em;
    }

    .descBlock {
        flex-direction: column;
    }

    .descBlock:nth-child(odd) {
        flex-direction: column;
    }

    .imgDesc {
        flex: 1 0 auto;
        height: 33vh;
        width: auto;
        background-position: center; /* Center the image */
        background-repeat: no-repeat; /* Do not repeat the image */
        background-size: cover; /* Resize the background image to cover the entire container */
        transition: all 0.5s;
    }

    .product-display-actions-container{
        position: initial;
    }
    .product-display-actions {
        position: initial;
    }

}
